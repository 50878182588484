<template>
    <b-container>
        <div v-if="settings.KVKAPI && !company.kvkcode" class="form-row">
            <div class="col-3">
                <label for=""> {{ $t("labels.search") }} {{ $t("labels.kvknumber") }}</label>

            </div>
            <div class="col">
                <b-form-input type="text" class="form-control" v-model="searchkvkcode" />
            </div>
            <div  class="col-3">
                <div v-if="searchkvkcode!=null" class="btn btn-primary" @click="searchKVK(); "><font-awesome-icon icon='search'   />{{ $t("labels.search") }} {{ $t("labels.kvknumber") }}
                </div>
            </div>
        </div>        
        <form class="form">
            <input type="hidden" :key="componentKey" />

            <!-- Company name -->
            <div class="form-row">
                <div class="col-3">
                    <font-awesome-icon v-if="companynamecheck" icon="check" color="green" />
                    <font-awesome-icon v-else icon="times" color="red" />
                    <label for="">{{ $t("labels.companyname") }}</label>
                </div>
                <div class="col">
                    <b-form-input type="text" class="form-control" :state="companynamecheck"
                        v-model="company.company_name"></b-form-input>
                    <b-form-invalid-feedback>
                        {{ $t("validation.companyname")}}
                    </b-form-invalid-feedback>
                </div>
            </div>
            <div class="form-row" v-if="false">
                <div class="col-3">
                    <!-- <font-awesome-icon v-if="companybeheercheck" icon="check" color="green" />
                    <font-awesome-icon v-else icon="times" color="red" /> -->
                    <label for="">{{ $t("labels.clientname") }}</label>
                </div>
                <div class="col">
                    <v-select :options="company_owners" label="name" :reduce="owner => owner.id" class="contactpersoon"
                        v-model="company.user_id"></v-select>
                </div>
            </div>

            <div class="form-row">
                <div class="col-3">
                    <font-awesome-icon v-if="companybranchecheck" icon="check" color="green" />
                    <font-awesome-icon v-else icon="times" color="red" />
                    <label for="">{{ $t("labels.branche") }}</label>
                </div>
                <div class="col">
                    <select name="branche" class="form-control opdrachtgever" v-model="company.branche_id">
                        <option v-for="(branche,idx) in activebranches" :key="idx" v-bind:value="branche.id">
                            {{ branche["name"]}}
                        </option>
                    </select>
                </div>
            </div>
            <!-- owner -->
            <div v-if="createAny('users')" class="form-row">
                <div class="col-3">
                    <label for=""> {{ $t("labels.addedby") }}</label>
                </div>
                <div class="col">

                    <select name="admin" class="form-control admins" v-model="company.owner_id">
                        <option v-for="(user,idx) in owners" :key="idx" v-bind:value="user.id">
                            {{ user["firstname"]}} {{ user["middlename"] }} {{ user["lastname"] }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-row">
                <div class="col-3">
                    <font-awesome-icon v-if="companyemailcheck" icon="check" color="green" />
                    <font-awesome-icon v-else icon="times" color="red" />
                    <label for="">{{ $t("labels.email") }}</label>
                </div>
                <div class="col">
                    <b-form-input type="email" class="form-control" :state="companyemailcheck"
                        v-model="company.email" />
                    <b-form-invalid-feedback>
                        {{ $t("validation.email")}}
                    </b-form-invalid-feedback>
                </div>
            </div>
            <div class="form-row">
                <div class="col-3">
                    <label for="">{{ $t("labels.website") }}</label>
                </div>
                <div class="col">
                    <input type="text" class="form-control" v-model="company.website">
                </div>
            </div>
            <!-- dynamic ordermetas -->
            <div v-for="meta in companies_metas" v-bind:key="meta.key">
                <div class="form-row" v-if="(company[meta.toggle] || !meta.toggle) && meta.label!='debnum' && meta.label!='finqle'&& meta.label!='needsattachment' && meta.label!='workhours' && meta.label!='signworkhours'">
                    <div class="col-3">
                        <label v-if="meta.label!='remarks'" v-bind:for="meta.key">{{translate(meta.label)}}</label>
                    </div>
                    <div v-if="meta.label=='Zipcode'" class="col"
                        v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
                        <b-input-group>
                            <b-form-input :state="lookup" v-bind:type="meta.type"
                                v-bind:placeholder=translate(meta.label) v-bind:id="meta.key" class="form-control"
                                v-model="company[meta.key]" />
                            <b-form-invalid-feedback>
                                {{postcodecheck}}
                            </b-form-invalid-feedback>
                        </b-input-group>
                    </div>
                    <div v-else-if="meta.label=='Housenumber'" class="col"
                        v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
                        <b-input-group>
                            <b-form-input :state="lookup" v-bind:type="meta.type"
                                v-bind:placeholder=translate(meta.label) v-bind:id="meta.key" class="form-control"
                                v-model="company[meta.key]" />
                        </b-input-group>
                    </div>
                    <div v-else-if="checktextareas(meta.label)" class="col"
                        v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
                        <b-input-group v-if="meta.label!='remarks'">
                            <b-form-textarea size="sm" rows="3" v-bind:type="meta.type"
                                v-bind:placeholder=translate(meta.label) v-bind:id="'bind-'+meta.key" :ref="meta.key"
                                class="form-control" v-model="company[meta.key]" />
                            <b-input-group-append v-if="createAny('users')">
                                <b-button squared class="multi-append"  href="javascript:;"
                                    @click="append_data_multi(meta.key)">
                                    <font-awesome-icon icon="plus-circle" />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    <div v-else-if="meta.label=='Country'" class="col"
                        v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
                        <b-input-group>
                            <select v-bind:type="meta.type" v-bind:id="meta.key" class="form-control"
                                v-model="company[meta.key]">
                                <option value='NL'>{{ $t("countries.NL") }}</option>
                                <option value='DE'>{{ $t("countries.DE") }}</option>
                                <option value='BE'>{{ $t("countries.BE") }}</option>
                                <option value='LT'>{{ $t("countries.LT") }}</option>
                                <option value='RO'>{{ $t("countries.RO") }}</option>
                                <option value='PL'>{{ $t("countries.PL") }}</option>
                                <option value='TR'>{{ $t("countries.TR") }}</option>
                            </select>
                        </b-input-group>
                    </div>
                    <div v-else-if="meta.type != 'select' && meta.label!='debnum'" class="col"
                        v-bind:class="{'col-4': meta.type == 'date', 'col-2': meta.type == 'number'}">
                        <input v-bind:type="meta.type" v-bind:placeholder=translate(meta.label) v-bind:id="meta.key"
                            class="form-control" v-model="company[meta.key]">
                    </div>

                    <div v-else>
                        <select v-if="meta.key=='rating'" name="branche" class="form-control" v-model="company[meta.key]">
                            <option val='1'>*</option>
                            <option val='2'>**</option>
                            <option val='3'>***</option>
                            <option val='4'>****</option>
                            <option val='5'>*****</option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
    </b-container>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex'
import popup_confirm from '../PopupConfirm.vue'

export default {
    name: 'companydefault',
    data() {
        return {
            //eslint-disable-next-line
            reg: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            postcodecheck: 'invalid',
            findadresresult: false,
            componentKey: 0,
            searchkvkcode:null

        }
    },
    props: {
        company_id: Number
    },
    mounted() {
    },
    computed: {
        company() {
            return this.company_id > 0 ? this.company_by_id(this.company_id) : this.company_new
        },
        company_owners() {
            let users = [];
            for (let owner of this.opdrachtgevers) {
                users.push({ id: owner.id, name: this.username_by_id(owner.id) })
            }
            return users
        },
        ...mapGetters([
            'companies_metas',
            'company_by_id',
            'opdrachtgevers',
            'activebranches',
            'activehourcategories',
            'company_new',
            'createAny',
            'owners',
            'append_data_string',
            'username_by_id'

        ]),
        ...mapState([
            'companies',
            'branches',
            'users'
        ]),

        companynamecheck() {
            if (this.company.id != 0) {
                return true
            }
            let found = false;
            if (this.company.company_name != undefined) {
                for (var i = 0; i < this.companies.length; i++) {
                    if (this.company.company_name.toLowerCase() == this.companies[i].company_name.toLowerCase()) {
                        found = true;
                        break;
                    }
                }
            }
            return !found && this.company.company_name != undefined && this.company.company_name.length > 2;
        },
        companyemailcheck() {
            console.log("gocheck",this.company.email)
            //eslint-disable-next-line
            return this.company.email != undefined && this.reg.test(this.company.email)
        },
        companybranchecheck() {
            return this.company.branche_id != undefined
        },
        companybeheercheck() {
            return this.company.user_id != undefined
        },
        lookup() {
            this.findadres();
            return true//this.findadresresult
        },


    },
    methods: {
        async searchKVK(){
            const response = await axios.get('/api/kvk/'+this.searchkvkcode)
            console.log(response)
            if(response.data.status==400){
                  this.$modal.hide('popup_confirm')
                  this.$modal.show(popup_confirm, {
                      message: this.$t('labels.kvkzoekfout'),
                      header: this.$t('labels.kvkcode')
                  }, {
                      name: 'popup_confirm',
                      draggable: false,
                      height: 'auto',
                      width: '400px'
                  })
            } else {
                this.$modal.hide('popup_confirm')
                  this.$modal.show(popup_confirm, {
                      message: this.$t('labels.kvkzoekgoed'),
                      header: this.$t('labels.kvkcode')
                  }, {
                      name: 'popup_confirm',
                      draggable: false,
                      height: 'auto',
                      width: '400px'
                  })
                this.company.company_name = response.data.naam?response.data.naam:""
                console.log(this.companynamecheck)
                this.company.kvk = "yes"
                this.company.kvkcode = this.searchkvkcode
                if(response.data._embedded && response.data._embedded.hoofdvestiging){
                    if(response.data._embedded.hoofdvestiging.adressen.length>0){
                        let adresdata = response.data._embedded.hoofdvestiging.adressen[0]
                        this.company.postcode = adresdata.postcode
                        this.company.housenumber = adresdata.huisnummer
                        this.company.adres = adresdata.straatnaam
                        this.company.city = adresdata.plaats
                        this.company.country= adresdata.latlongaddress
                    }
                }
            }
        },          
        checktextareas(label) {
            let areas = ["clientele", "worktype", "administratie", "indruk", "remarks"]
            return (areas.indexOf(label.toLowerCase()) != -1)
        },
        findadres() {
            if(this.company.postcode){
                var numbers = this.company.postcode.substring(0,4)
                var letters = this.company.postcode.substring(4).replaceAll(' ', '').toUpperCase().substring(0,2)
                if(letters.length==2 && numbers.length==4){
                    this.company.postcode = numbers+ " "+ letters
                    this.count++
                }
            }

            fetch(
                "https://api.pro6pp.nl/v2/autocomplete/nl?authKey=1u9iXssOsxr8wRR5&postalCode=" + this.company.postcode + "&streetNumberAndPremise="+this.company.housenumber,
                {
                    method: "GET",
                }
            )
            .then( async response => {
                const data = await response.json();
                console.log(data)
                if (!response.ok) {
                    console.log("ERRORtje")
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                this.company.adres = data.street
                this.company.city = data.settlement
                this.company.province = data.province
                this.company.lat = data.lat
                this.company.lng = data.lng
                this.company.country = data.countryCode
                this.company.latlongaddress = this.company.adres + " " + this.company.housenumber + " " + this.company.city + " " + this.company.country
                this.findadresresult = true
                this.componentKey++

            })
            .catch((err) => {
                console.error("DASNIETGOED",err);
            });
        },

        translate(path) {
            return this.$i18n.t('meta.' + path.toLowerCase())
        },
        validated() {
            return this.companynamecheck && this.companyemailcheck && this.companybranchecheck //&& this.companybeheercheck
        },
        append_data_multi(key) {
            var current = ""
            if (this.company[key]) {
                current = "\n" + this.company[key]
            }
            this.company[key] = this.append_data_string + current
            this.componentKey += 1;
            if (this.$refs[key]) {
                if (this.$refs[key][0]) {
                    this.$refs[key][0].focus()
                    this.$nextTick(() => {
                        this.$refs[key][0].selectionEnd = 9
                    })
                } else {
                    this.$refs[key].focus()
                    this.$nextTick(() => {
                        this.$refs[key].selectionEnd = 9
                    })
                }
            }
        },

    }
}
</script>            